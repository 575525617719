import * as React from 'react';
import {Tab, Box} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {useState} from "react";
import {Link} from "react-router-dom";


export interface NavigationProps {
    selectedTab: string;
}
const NavigationTabs = (props : NavigationProps) => {
    const [value, setValue] = useState(props.selectedTab);

    const handleChange = (event: any, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ paddingTop: {
                xs: "48px",
                sm: "94px"
            }, paddingBottom:"30px", width: "100%" }}>
            <Box className="hidden sm:block" >
                <TabContext value={value}>
                    <Box sx={{ paddingLeft:"50px" }}>
                        <TabList onChange={handleChange} textColor="secondary"
                                 indicatorColor="secondary">
                            <Tab sx={{ fontSize:"18px", fontWeight:"bold", padding:"0px 50px 0px 50px", textTransform: "unset"}} label="Home" value={"0"} component={Link} to={"/"} />
                            <Tab sx={{ fontSize:"18px", fontWeight:"bold", padding:"0px 50px 0px 50px",textTransform: "unset" }} label="Employers" value={"1"} component={Link} to={"/employers"} />
                            <Tab sx={{ fontSize:"18px", fontWeight:"bold", padding:"0px 50px 0px 50px",textTransform: "unset" }} label="Providers" value={"2"} component={Link} to={"/providers"} />
                            <Tab sx={{ fontSize:"18px", fontWeight:"bold", padding:"0px 50px 0px 50px",textTransform: "unset" }} label="Services" value={"3"} component={Link} to={"/services"} />
                            <Tab sx={{ fontSize:"18px", fontWeight:"bold", padding:"0px 50px 0px 50px",textTransform: "unset" }} label="Content" value={"4"} component={Link} to={"/content"} />
                        </TabList>
                    </Box>
                </TabContext>
            </Box>
        </Box>
    );
}

export default NavigationTabs;