import { Image } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {UploadImageProps} from "../types/types";
import {useAppSelector} from "../store/hooks";
import UploadService from "../services/fileUpload-service";


const UploadImageComponent = (
  props: UploadImageProps & {
    showUploadControls?: boolean;
    onImageSelected?: (image: File | null) => void;
    originalImageSource: string | undefined;
    contentId: string;
  },
) => {
    const accessToken = useAppSelector((state) => state.auth.token);

  const [imageFile, setImageFile] = useState<File>();
  const [validImage, setValidImage] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [originalImage, setOriginalImage] = useState(null as string | null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.originalImageSource) {
        setOriginalImage(props.originalImageSource);
    }
  }, [props.originalImageSource]);

  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;
    const file = selectedFiles?.[0];

    setValidImage(false);
    props.onImageSelected && props.onImageSelected(null);
    setErrorMessage("");
    if (file) {
      if (file?.size / 1024 < 1024) {
        setImageFile(file);
        setPreviewImage(URL.createObjectURL(selectedFiles?.[0]));
        setValidImage(true);
        props.onImageSelected && props.onImageSelected(file);
      } else {
        setErrorMessage(
          "The uploaded image is too big. Make sure the file is not bigger than 1 MB.",
        );
      }
    }
  };

  const cancelUpload = () => {
    setImageFile(undefined);
    setPreviewImage("");
  };

  const upload = () => {
      if (!imageFile) return;
      UploadService.uploadContentImage(imageFile, accessToken, props.contentId)
          .then((res) => {
              setValidImage(false);
              toast.success("Your image has been successfully uploaded.");
          })
          .catch((err) => {
              if (
                  err.response &&
                  err.response.status &&
                  err.response!.status === 406
              ) {
                  toast.error(
                      "We can only allow you to upload image files. Please try again.",
                  );
              } else {
                  toast.error(
                      "There was problem uploading your image. Please try again.",
                  );
              }
          });
  };

  return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "100%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {previewImage && (
            <img
              style={{
                borderRadius: "10px",
                cursor: "pointer",
                maxWidth: "200px",
                height: "100px",
              }}
              src={previewImage}
              alt={props.altText}
            />
          )}

          {originalImage && !previewImage && (
            <img
              style={{
                borderRadius: "10px",
                cursor: "pointer",
                maxWidth: "200px",
                height: "100px",
              }}
              src={originalImage}
              alt={props.altText}
            />
          )}

          {!previewImage && !originalImage && (
            <div className="flex items-center justify-center m-auto border-dashed border-[1px] border-[#ced4da] rounded-lg w-[200px] h-[100px] bg-white">
              <Image
                data-testid="image-placeholder-icon"
                sx={{
                  width: "48px",
                  height: "48px",
                  fill: "#CED4DA",
                }}
              />
            </div>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            variant="contained"
            component="label"
            color="primary"
            sx={{
              marginTop: "1rem",
              maxWidth: { xs: "100%", sm: "150px" },
              borderRadius: "10px",
            }}
          >
            Select image
            <input
              hidden
              type="file"
              accept="image/*"
              onChange={selectImage}
              data-testid={"upload-image-input"}
            />
          </Button>
          <div>
            {errorMessage ? (
              <Typography
                variant="body2"
                color="red"
                sx={{ marginTop: "0.5rem" }}
              >
                {errorMessage}
              </Typography>
            ) : (
              <div />
            )}
          </div>
          {props.showUploadControls && (
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row-reverse",
                },
                width: "100%",
                gap: "16px",
              }}
            >
              <Button
                sx={{
                  maxWidth: { xs: "100%" },
                  borderRadius: "10px",
                  padding: "8px 24px",
                }}
                color="primary"
                variant="contained"
                disabled={!validImage}
                onClick={upload}
              >
                Upload
              </Button>
              <Button
                sx={{
                  maxWidth: { xs: "100%", sm: "140px" },
                  borderRadius: "10px",
                }}
                color="primary"
                variant="outlined"
                onClick={cancelUpload}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Box>
  );
};

export default UploadImageComponent;
