import Header from "../components/Header";
import NavigationTabs from "../components/NavigationTabs";
import {
    Backdrop,
    Box,
    Button, FormControl, FormControlLabel, MenuItem, Modal,
    Paper, Radio, RadioGroup, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import {toast, ToastContainer} from "react-toastify";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../store/hooks";
import {Content, HealthCategory, Product} from "../types/types";
import axios from "axios";
import authSlice from "../store/slices/auth";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import moment, { now } from "moment";
import UploadImageComponent from "../components/UploadImageComponent";


const ContentPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accessToken = useAppSelector(state => state.auth.token);
    const providers = useAppSelector(state => state.providers.providers);

    const [allContent, setAllContent] = useState<Content[]>([]);
    const [editContentModalIsOpen, setEditContentModalIsOpen] = useState(false);
    const [editContentCategoriesModalIsOpen, setEditContentCategoriesModalIsOpen] = useState(false);
    const [selectedContent, setSelectedContent] = useState<Content | undefined>(undefined);
    const [contentHealthCategories, setContentHealthCategories] = useState<Array<HealthCategory>>(new Array());
    const [selectedContentCategories, setSelectedContentCategories] = useState<Array<HealthCategory>>(new Array());
    const [errorMessage, setErrorMessage] = useState("");
    const [createContentOpen, setCreateContentOpen] = useState(false);
    const [contentType, setContentType] = useState("text");
    const [contentTitle, setContentTitle] = useState<string | undefined>();
    const [contentAuthor, setContentAuthor] = useState<string | undefined>();
    const [contentContent, setContentContent] = useState<string | undefined>();
    const [contentSourceUrl, setContentSourceUrl] = useState<string | undefined>();
    const [contentSourceName, setContentSourceName] = useState<string | undefined>();
    const [contentDatePublished, setContentDatePublished] = useState<string | undefined>(moment(new Date()).format("DD/MM/YYYY"));
    const [contentShortDescription, setContentShortDescription] = useState<string | undefined>();
    const [contentLength, setContentLength] = useState<string | undefined>();
    const [contentImageUrl, setContentImageUrl] = useState<string | undefined>();

    useEffect( () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        readAllContent();
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/healthCategories`, {headers: headers}).then((res) => {
            setContentHealthCategories(res.data);
        })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }, [])

    useEffect(() => {
        let datePublishedInput = document.getElementById("datePublished");
        if (datePublishedInput != null) {
            datePublishedInput.setAttribute("max", moment(now()).format("YYYY-MM-DD"));
        }
        if (selectedContent && selectedContent.datePublished) {
            setContentDatePublished(moment(selectedContent.datePublished).format("YYYY-MM-DD"));
        }
    }, [selectedContent]);

    const expandEditContentModal = (content: Content) => {
        setEditContentModalIsOpen(true);
        setSelectedContent(content);
        setContentSourceUrl(content.sourceURL);
        setContentSourceName(content.sourceName);
        setContentTitle(content.title);
        setContentAuthor(content.author);
        setContentType(content.type);
        setContentContent(content.content);
        setContentImageUrl(content.imageUrl);
        setContentLength(content.length);
        setContentShortDescription(content.shortDescription);
    }

    const expandEditContentCategoriesModal = (content: Content) => {
        setEditContentCategoriesModalIsOpen(true);
        setSelectedContent(content);

        let categories = new Array();
        if (content.categories) {
            for (let categoryId of content.categories) {
                categories.push(getHealthCategoryById(categoryId));
            }
        }
        setSelectedContentCategories(categories);
    }

    const getHealthCategoryById = (id: string) : HealthCategory | undefined => {
        for (let category of contentHealthCategories) {
            if (category.id === id) {
                return category;
            }
        }
        return undefined;
    }

    const closeEditContentModal = () => {
        setEditContentModalIsOpen(false);
        setErrorMessage("");
    }

    const closeEditContentCategoriesModal = () => {
        setEditContentCategoriesModalIsOpen(false);
        setErrorMessage("");
    }

    const selectCategory = (category: HealthCategory) => {
        if (selectedContentCategories.length >= 3) {
            setErrorMessage("You can only select up to three categories for your content.")
        } else {
            let selectedCategories = new Array();
            for (let alreadySelected of selectedContentCategories) {
                selectedCategories.push(alreadySelected);
            }
            selectedCategories.push(category);
            setSelectedContentCategories(selectedCategories);
        }
    }

    const unselectCategory = (category: HealthCategory) => {
        setErrorMessage("");
        let selectedCategories = new Array();
        for (let alreadySelected of selectedContentCategories) {
            if (alreadySelected.id !== category.id) {
                selectedCategories.push(alreadySelected);
            }
        }
        setSelectedContentCategories(selectedCategories);
    }

    const isSelectedCategory = (category: HealthCategory) : boolean => {
        if (selectedContentCategories) {
            for (let selectedCategory of selectedContentCategories) {
                if (selectedCategory.id === category.id) {
                    return true;
                }
            }
        }
        return false;
    }
    const updateContentChanges = () => {
        if (selectedContent) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }

            axios
                .patch(`${process.env.REACT_APP_SERVER_API_URL}/contents/` + selectedContent.id, { title: contentTitle, content:contentContent, type:contentType, sourceURL:contentSourceUrl, sourceName: contentSourceName, author:contentAuthor, shortDescription: contentShortDescription, datePublished: contentDatePublished, length: contentLength},{headers: headers}).then((res) => {
                readAllContent();
                closeEditContentModal();
            })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }
    }

    const updateContentCategoriesChanges = () => {
        if (selectedContent) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            let categories = new Array();
            for (let category of selectedContentCategories) {
                categories.push(category.id);
            }
            axios
                .patch(`${process.env.REACT_APP_SERVER_API_URL}/contents/` + selectedContent.id, { categories},{headers: headers}).then((res) => {
                readAllContent();
                closeEditContentCategoriesModal();
            })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }
    }

    const readAllContent = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/contents`, {headers: headers}).then((res) => {
            setAllContent(res.data);
        })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }

    const largeModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1300,
        bgcolor: 'background.paper',
        border: '1px solid #D9D9D959',
        borderRadius: '10px',
        p: 4,
    };

    const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContentType((event.target as HTMLInputElement).value);
    };

    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const createContent = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios
            .post(`${process.env.REACT_APP_SERVER_API_URL}/contents`, { title: contentTitle, content:contentContent, type:contentType, sourceURL:contentSourceUrl, sourceName: contentSourceName, author:contentAuthor, shortDescription: contentShortDescription, datePublished: contentDatePublished, length: contentLength}, {headers: headers})
            .then((res) => {
                readAllContent();
                setCreateContentOpen(false);
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }

    const handleChangeDate = (e) => {
        setContentDatePublished(e.target.value);
    };

    const contentEditForm = <div className="flex flex-row gap-4"><div className="flex flex-col mb-4 w-1/3">
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={contentType}
                row
                onChange={handleTypeChange}
            >
                <FormControlLabel
                    value="text"
                    control={<Radio />}
                    label="Text"
                />
                <FormControlLabel
                    value="video"
                    control={<Radio />}
                    label="Video"
                />
            </RadioGroup>
        </FormControl>
        <TextField
            id="contentTitle"
            name="contentTitle"
            label="Title"
            variant="standard"
            margin="normal"
            type="text"
            fullWidth
            sx={visibleTextfield}
            value={contentTitle}
            onChange={e => setContentTitle(e.target.value)}
        />
        <TextField
            id="contentAuthor"
            name="contentAuthor"
            label="Author"
            variant="standard"
            margin="normal"
            type="text"
            fullWidth
            sx={visibleTextfield}
            value={contentAuthor}
            onChange={e => setContentAuthor(e.target.value)}
        />
        <div className="flex flex-col lg:flex-row gap-2">
        <div className="w-full lg:w-1/2 flex flex-col">
            <Typography
                id="datePublishedLabel"
                component="label"
                marginBottom={"4px"}
            >
                Date published
            </Typography>
            <TextField
                id="datePublished"
                aria-labelledby="datePublishedLabel"
                name="datePublished"
                type="date"
                fullWidth
                value={contentDatePublished}
                onChange={handleChangeDate}
            />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col">
            <Typography
                id="durationLabel"
                component="label"
                marginBottom={"4px"}
            >
                Duration
            </Typography>
            <TextField
                id="durationTextfield"
                aria-labelledby="durationLabel"
                name="durationTextfield"
                type="number"
                fullWidth
                value={contentLength}
                onChange={e => setContentLength(e.target.value)}
            />
        </div>
        </div>
        <TextField
            id="contentSourceUrl"
            name="contentSourceUrl"
            label="Source URL"
            variant="standard"
            margin="normal"
            type="text"
            fullWidth
            sx={visibleTextfield}
            value={contentSourceUrl}
            onChange={e => setContentSourceUrl(e.target.value)}
        />
        <TextField
            id="contentSourceName"
            name="contentSourceName"
            label="Source name"
            variant="standard"
            margin="normal"
            type="text"
            fullWidth
            sx={visibleTextfield}
            value={contentSourceName}
            onChange={e => setContentSourceName(e.target.value)}
        />
        <TextField
            id="contentShortDescription"
            name="contentShortDescription"
            label="Short description"
            variant="standard"
            margin="normal"
            type="text"
            fullWidth
            multiline
            rows={6}
            sx={visibleTextfield}
            value={contentShortDescription}
            onChange={e => setContentShortDescription(e.target.value)}
        />
    </div>
        <div className="flex flex-col gap-4 w-2/3 max-h-[600px] overflow-y-scroll">
            {contentType === 'text' && <ReactQuill
                value={contentContent}
                onChange={(value) => {setContentContent(value)}}
            />}
            {contentType === 'video' && <TextField
                id="contentContent"
                name="contentShortDescription"
                label="Video iframe"
                variant="standard"
                margin="normal"
                type="text"
                fullWidth
                multiline
                rows={6}
                sx={visibleTextfield}
                value={contentContent}
                onChange={e => setContentContent(e.target.value)}
            />}
            {selectedContent && <div className="flex flex-row mt-10">
                <UploadImageComponent
                    imageType={"image"}
                    title={"Your logo"}
                    altText={"Logo"}
                    showUploadControls={true}
                    originalImageSource={contentImageUrl}
                    contentId={selectedContent.id}
                />
            </div> }
    </div>
    </div>
    return <Box sx={{background:"#F5F5F5"}}>
        <Header />
        <NavigationTabs selectedTab={"4"} />
        <Box sx={{paddingLeft: {
                xs:"16px",
                sm:"50px"
            }, paddingRight: {
                xs: "16px",
                sm: "50px"
            }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">

            <div className="bg-white rounded-lg border-grey shadow-md py-4 px-4 sm:py-8 sm:px-8 w-full">
                <div className="w-full flex flex-row-reverse p-4">
                    <Button variant="contained" onClick={() => setCreateContentOpen(true)}>Create new content</Button>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell align="right">Source URL</TableCell>
                                <TableCell align="right">Type</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allContent.map((content) => (
                                <TableRow
                                    key={content.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {content.title}
                                    </TableCell>
                                    <TableCell align="right">{content.sourceURL}</TableCell>
                                    <TableCell align="right">{content.type}</TableCell>
                                    <TableCell>
                                        <div className="flex flex-row-reverse flex-wrap">
                                            {content.categories.map((category) => (
                                                <div key={category}>
                                                    <Box sx={{borderRadius: "10px", background:"#E9F5E3", color:"#242426", padding:"5px 10px 5px 10px", margin:{xs:"8px 0px 8px 0px", sm:"8px 0px 8px 16px"}}}>
                                                        <Typography variant="body2" align="center" color="#27104E" >
                                                            {getHealthCategoryById(category)?.name}
                                                        </Typography>
                                                    </Box>
                                                </div>
                                            ))}
                                        </div>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button variant="contained" onClick={() => {expandEditContentModal(content);}}>
                                            Edit Content
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button variant="contained" onClick={() => {expandEditContentCategoriesModal(content);}}>
                                            Edit Categories
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Box>
        <Modal
            open={editContentModalIsOpen}
            onClose={closeEditContentModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}>
            <Box sx={largeModalStyle}>
                {contentEditForm}
                <Box sx={{marginTop: "16px;"}}>
                    <Button color="secondary" variant="contained" type="submit" onClick={() => updateContentChanges()}>
                        Save changes
                    </Button>
                </Box>
            </Box>
        </Modal>
        <Modal
            open={editContentCategoriesModalIsOpen}
            onClose={closeEditContentCategoriesModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}>
            <Box sx={largeModalStyle}>
                <Typography variant="body1" gutterBottom>Please select up to three health categories:</Typography>
                <div className="flex flex-row flex-wrap">
                    {contentHealthCategories.map((category) => (
                        <div key={category.htmlId}>
                            {isSelectedCategory(category) ? (
                                <Box sx={{borderRadius: "10px", background:"#E9F5E3", color:"#242426", padding:"5px 10px 5px 10px", margin:{xs:"8px 0px 8px 0px", sm:"8px 16px 8px 0px"}}} onClick={() => unselectCategory(category)}>
                                    <Typography variant="body2" align="center" color="#27104E" >
                                        {category.name}
                                    </Typography>
                                </Box>
                            ) : (
                                <Box sx={{borderRadius: "10px", background:"#FCF0F8", color:"#242426", padding:"5px 10px 5px 10px", margin:{xs:"8px 0px 8px 0px", sm:"8px 16px 8px 0px"}}} onClick={() => selectCategory(category)}>
                                    <Typography variant="body2" align="center" color="#27104E" >
                                        {category.name}
                                    </Typography>
                                </Box>
                            )}
                        </div>
                    ))}
                </div>
                <div>
                    <Typography variant="body1" color="error">{errorMessage}</Typography>
                </div>
                <Box sx={{marginTop: "16px;"}}>
                    <Button color="secondary" variant="contained" type="submit" onClick={() => updateContentCategoriesChanges()}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Modal>
        <Modal
            open={createContentOpen}
            onClose={() => setCreateContentOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}>
            <Box sx={largeModalStyle}>
                {contentEditForm}
                        <div className="py-8 flex flex-row-reverse">
                            <Button variant="contained" onClick={createContent}>Create content</Button>
                        </div>
            </Box>
        </Modal>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeButton={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={1}
            theme="colored"
        />
    </Box>
}

export default ContentPage;