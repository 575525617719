import React, {useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import OutsideTitleTextComponent from "../components/OutsideTitleTextComponent";

function SessionExpired() {

    return (
        <OutsideTitleTextComponent displayButton={true} text={"It seems that your session has expired. If you would like to continue using the HealthKey platform, please login again."} title={"Your session has expired."} secondText={""} />
    );
}

export default SessionExpired;