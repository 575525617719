import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import NavigationTabs from "../components/NavigationTabs";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../store/hooks";

import axios from "axios";
import {
    Box,
    Typography
} from "@mui/material";

import {useNavigate} from "react-router-dom";
import authSlice from "../store/slices/auth";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../components/Footer";
import employer from "../store/slices/employer";
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    LabelList,
    Legend, Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer, Tooltip, XAxis, YAxis
} from "recharts";
import provider from "../store/slices/providers";
import users from "../store/slices/users";
import {HealthCategory} from "../types/types";

const Home = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loggedInUser = useAppSelector(state => state.userDetails.user);
    const accessToken = useAppSelector(state => state.auth.token);
    const providers = useAppSelector(state => state.providers.providers);
    const usersRegistered = useAppSelector(state => state.users.usersRegistered);

    const totalEmployerSpendState = useAppSelector(state => state.employer.totalEmployerSpend);
    const totalUserSpendState = useAppSelector(state => state.employer.totalUserSpend);
    const totalGiftSpendState = useAppSelector(state => state.employer.totalGiftSpend);
    const totalSpendState = useAppSelector(state => state.employer.totalSpend);
    const totalUserTopUpState = useAppSelector(state => state.employer.totalUserTopUp);
    const totalGiftTopUpState = useAppSelector(state => state.employer.totalGiftTopUp);
    const totalEmployerTopUpState = useAppSelector(state => state.employer.totalEmployerTopUp);

    const [totalUserSpend, setTotalUserSpend] = useState(0);
    const [totalSpend, setTotalSpend] = useState(0);
    const [totalUserTopUp, setTotalUserTopUp] = useState(0);
    const [totalGiftTopUp, setTotalGiftTopUp] = useState(0);
    const [totalEmployerTopUp, setTotalEmployerTopUp] = useState(0);
    const [totalEmployerSpend, setTotalEmployerSpend] = useState(0);
    const [totalGiftSpend, setTotalGiftSpend] = useState(0);
    const [providersSpendGraph, setProvidersSpendGraph] = useState(new Array<any>());
    const [providersColors, setProvidersColors] = useState(new Array<string>());
    const [categoriesSpendGraph, setCategoriesSpendGraph] = useState(new Array<any>());
    const [categoriesColors, setCategoriesColors] = useState(new Array<string>());
    const [usersRegistrationGraph, setUsersRegistrationGraph] = useState(new Array<any>());

    const [loadedUsersData, setLoadedUsersData] = useState(false);
    const [loadedProviderData, setLoadedProviderData] = useState(false);
    const [loadedCategoriesData, setLoadedCategoriesData] = useState(false);

    const [providerHealthCategories, setProviderHealthCategories] = useState<Array<HealthCategory>>(new Array());



    useEffect(() => {
        if (loggedInUser) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/admin/employers`, {headers: headers})
                .then((res) => {
                    dispatch(employer.actions.setEmployers(res.data));
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/admin/users`, {headers: headers})
                .then((res) => {
                    dispatch(users.actions.setUsersInformation(res.data.usersRegistered));
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/providerHealthCategories`, {headers: headers})
                .then((res) => {
                    setProviderHealthCategories(res.data);
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });

            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/admin/providers`, {headers: headers})
                .then((res) => {
                    dispatch(provider.actions.setProviders(res.data));
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });


        }
    }, [loggedInUser, accessToken, dispatch])

    const getHealthCategoryName = (id: string) : string | undefined => {
        let categoryName;
        if (providerHealthCategories) {
            for (let category of providerHealthCategories) {
                if (category.id === id) {
                    return category.name
                }
            }
        }
        return categoryName;
    }

    useEffect(() => {
        if (!loadedProviderData) {
            let providersGraph = new Array<any>();
            let colors = new Array<string>();
            let limit=5;
            let i=0;

            for (let p of providers) {
                if (p.totalMoneySpent > 0) {
                        colors.push(`rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`);
                        providersGraph.push(
                            {
                                name: '£' + p.totalMoneySpent.toFixed(2) + ' - ' + p.name,
                                value: p.totalMoneySpent
                            }
                        )
                    i++;
                }
            }


            providersGraph.sort((a,b) => (a.value > b.value) ? -1 : ((b.value > a.value) ? 1 : 0))
            if (providersGraph.length > limit) {
                let otherProvidersSpend = 0;
                for (let i=limit; i < providersGraph.length; i++) {
                    otherProvidersSpend = otherProvidersSpend + providersGraph[i].value;
                }
                providersGraph.splice(limit);
                providersGraph.push({
                    name: "Other Providers",
                    value: otherProvidersSpend
                })
            }

            setProvidersColors(colors);
            setProvidersSpendGraph(providersGraph);
            setLoadedProviderData(true);
        }
    }, [providers, loadedProviderData])

    useEffect(() => {
        if (!loadedCategoriesData && providerHealthCategories && providerHealthCategories.length > 0) {
            let categoriesSpend = new Map<string,number>();
            let categoriesGraph = new Array<any>();
            let colorsCategories = new Array<string>();
            let limit=5;
            let i=0;

            for (let p of providers) {
                if (p.totalMoneySpent > 0) {
                    let providerCategory = getHealthCategoryName(p.healthCategoryId);
                    if (providerCategory) {
                        if (categoriesSpend.has(providerCategory)) {
                            let spend = categoriesSpend.get(providerCategory);
                            categoriesSpend.set(providerCategory, spend! + p.totalMoneySpent);
                        } else {
                            categoriesSpend.set(providerCategory, p.totalMoneySpent);
                        }
                    }
                    i++;
                }
            }

            categoriesSpend.forEach((value: number, key: string) => {
                categoriesGraph.push(
                    {
                        name: '£' + value.toFixed(2) + ' - ' + key,
                        value: value
                    }
                )
                colorsCategories.push(`rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`);

            });


            categoriesGraph.sort((a,b) => (a.value > b.value) ? -1 : ((b.value > a.value) ? 1 : 0))
            if (categoriesGraph.length > limit) {
                let otherCategoriesSpend = 0;
                for (let i=limit; i < categoriesGraph.length; i++) {
                    otherCategoriesSpend = otherCategoriesSpend + categoriesGraph[i].value;
                }
                categoriesGraph.splice(limit);
                categoriesGraph.push({
                    name: "Other Categories",
                    value: otherCategoriesSpend
                })
            }

            setCategoriesColors(colorsCategories);
            setCategoriesSpendGraph(categoriesGraph);
            setLoadedCategoriesData(true);
        }
    }, [providerHealthCategories, loadedCategoriesData])

    useEffect(() => {
        setTotalSpend(totalSpendState);
        setTotalUserSpend(totalUserSpendState);
        setTotalEmployerSpend(totalEmployerSpendState);
        setTotalUserTopUp(totalUserTopUpState);
        setTotalEmployerTopUp(totalEmployerTopUpState);
        setTotalGiftTopUp(totalGiftTopUpState);
        setTotalGiftSpend(totalGiftSpendState)
    }, [totalEmployerSpendState, totalUserSpendState, totalSpendState, totalUserTopUpState, totalEmployerTopUp, totalGiftTopUpState, totalGiftSpendState])


    useEffect(() => {
        if (!loadedUsersData && usersRegistered) {
            let usersGraph = new Array<any>();
            let totalNumberOfUsers = 0;
            for (let ur of usersRegistered) {
            totalNumberOfUsers = totalNumberOfUsers + ur.numberOfUsers;
                usersGraph.push(
                    {
                        name: ur.registeredOn.toString(),
                        users: totalNumberOfUsers
                    }
                )
            }
            setUsersRegistrationGraph(usersGraph);
            setLoadedUsersData(true);
        }
    }, [usersRegistered, loadedUsersData])

    function rand(frm, to) {
        return ~~(Math.random() * (to - frm)) + frm;
    }

    const COLORS_SPEND = ['#4860E6', '#27104E', '#FF8042'];


    const spendGraph = [
            {
                name: "£" +  (Math.round((totalEmployerSpend * 100) / 100)).toFixed(2) + " spent from employer",
                value: totalEmployerSpend
            },
        {
            name: "£" +  (Math.round(totalUserSpend * 100) / 100).toFixed(2) + " spent from users",
            value: totalUserSpend
        }
        ,
        {
            name: "£" +  (Math.round(totalGiftSpend * 100) / 100).toFixed(2) + " spent from gifts",
            value: totalGiftSpend
        }]

    const topUpGraph = [
        {
            name: "£" +  (Math.round((totalEmployerTopUp * 100) / 100)).toFixed(2) + " from employer",
            value: totalEmployerTopUp
        },
        {
            name: "£" +  (Math.round(totalUserTopUp * 100) / 100).toFixed(2) + " from users",
            value: totalUserTopUp
        }
        ,
        {
            name: "£" +  (Math.round(totalGiftTopUp * 100) / 100).toFixed(2) + " from gifts",
            value: totalGiftTopUp
        }]

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <Box sx={{background:"#F5F5F5"}}>
            <Header />
            <NavigationTabs selectedTab={"0"} />
            <Box sx={{paddingLeft: {
                    xs:"16px",
                    sm:"50px"
                }, paddingRight: {
                    xs: "16px",
                    sm: "50px"
                }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">
                <div className="hidden sm:block mr-5 w-3/5 flex flex-col">
                    <div className="bg-white rounded-lg border-grey shadow-md py-8 px-8 h-full">
                        <Typography fontWeight="bold" sx={{width: "100%"}} mb={2} variant="h6">Spend percentage: {((totalSpend * 100) / (totalUserTopUp + totalEmployerTopUp)).toFixed(2)}%</Typography>
                        <Box sx={{display:"flex", flexDirection:"row"}}>
                            <Box sx={{marginRight:"2rem"}}>
                                <Typography fontWeight="bold" sx={{width: "100%"}} mb={2} variant="h6" color="secondary">Total money spent on HealthKey</Typography>
                                <Box sx={{ width: "100%", height:"95%"}} color="primary">
                                    <PieChart key={Math.random()} width={300} height={280}>
                                        <Legend layout="horizontal" fontSize="14px" fontFamily="Poppins" align="left"
                                                verticalAlign="bottom" iconType="circle"/>
                                        <Pie
                                            data={spendGraph}
                                            cx={100}
                                            cy={100}
                                            innerRadius={60}
                                            outerRadius={80}
                                            cornerRadius={20}
                                            fill="#8884d8"
                                            paddingAngle={5}
                                            dataKey="value"
                                        >
                                            {spendGraph.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS_SPEND[index % COLORS_SPEND.length]}/>
                                            ))}
                                            <Label position="center" fontWeight="bold" value={"£" + (Math.round(totalSpend * 100) / 100).toFixed(2)}
                                                   fill="#27104E"/>
                                        </Pie>
                                    </PieChart>
                            </Box>
                            </Box>
                            <Box>
                                <Typography fontWeight="bold" sx={{width: "100%"}} mb={2} variant="h6" color="secondary">Total money put on HealthKey</Typography>
                                <Box sx={{ width: "100%", height:"95%"}} color="primary">
                                    <PieChart key={Math.random()} width={300} height={280}>
                                        <Legend layout="horizontal" fontSize="14px" fontFamily="Poppins" align="left"
                                                verticalAlign="bottom" iconType="circle"/>
                                        <Pie
                                            data={topUpGraph}
                                            cx={100}
                                            cy={100}
                                            innerRadius={60}
                                            outerRadius={80}
                                            cornerRadius={20}
                                            fill="#8884d8"
                                            paddingAngle={5}
                                            dataKey="value"
                                        >
                                            {topUpGraph.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS_SPEND[index % COLORS_SPEND.length]}/>
                                            ))}
                                            <Label position="center" fontWeight="bold" value={"£" + (Math.round((totalUserTopUp + totalEmployerTopUp) * 100) / 100).toFixed(2)}
                                                   fill="#27104E"/>
                                        </Pie>
                                    </PieChart>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </div>

                <div style={{height:"500px", maxHeight:"screen"}} className="bg-white rounded-lg border-grey shadow-md sm:mr-5 py-4 px-4 sm:py-8 sm:px-8 sm:w-1/5 w-full">
                    {loadedProviderData ? (<>
                    <Typography fontWeight="bold" sx={{width: "100%"}} mb={2} variant="h6" color="secondary">Provider spend</Typography>
                        <ResponsiveContainer width={250} height="95%">
                        <PieChart key={Math.random()}>
                            <Legend layout="horizontal" fontSize="14px" fontFamily="Poppins" align="left"
                                    verticalAlign="bottom" iconType="circle"/>
                            <Pie
                                data={providersSpendGraph}
                                cx={100}
                                cy={90}
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {providersSpendGraph.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={providersColors[index % providersColors.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                        </ResponsiveContainer>
                    </>) : (<div/>)}
                </div>

                <div style={{height:"500px", maxHeight:"screen"}} className="bg-white rounded-lg border-grey shadow-md sm:mr-5 py-4 px-4 sm:py-8 sm:px-8 sm:w-1/5 w-full">
                    {loadedCategoriesData ? (<>
                        <Typography fontWeight="bold" sx={{width: "100%"}} mb={2} variant="h6" color="secondary">Health areas spend</Typography>
                        <ResponsiveContainer width={250} height="95%" debounce={1}>
                            <PieChart key={Math.random()} width={250} height={350}>
                                <Legend layout="horizontal" fontSize="14px" fontFamily="Poppins" align="left"
                                        verticalAlign="bottom" iconType="circle"/>
                                <Pie
                                    data={categoriesSpendGraph}
                                    cx={100}
                                    cy={90}
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {categoriesSpendGraph.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={categoriesColors[index % categoriesColors.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </>) : (<div/>)}
                </div>
            </Box>
            <Box sx={{paddingLeft: {
                    xs:"16px",
                    sm:"50px"
                }, paddingRight: {
                    xs: "16px",
                    sm: "50px"
                }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">

                <div className="hidden sm:block mr-5 mt-5 w-full flex flex-col">
                    <div className="bg-white rounded-lg border-grey shadow-md py-8 px-8 h-full">
                        {loadedUsersData ? (<>
                            <Typography fontWeight="bold" sx={{width: "100%"}} mb={2} variant="h6" color="secondary">Users on the platform</Typography>
                            <ResponsiveContainer width="100%" height={300}>
                            <AreaChart
                                width={500}
                                height={400}
                                data={usersRegistrationGraph}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="users" stroke="#27104E" fill="#4860E6" />
                            </AreaChart>
                        </ResponsiveContainer>
                        </>) : (<div/>)}
                    </div>
                </div>
            </Box>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Footer displayLogo={true} selectedTab={"0"}/>
        </Box>
    );
};

export default Home;