import {Box, Typography} from "@mui/material";
import logo from "../images/logo_no_text.png";

function OutsideLeftComponent() {
    return <div className="hidden sm:block sm:w-1/3">
        <div className="sm:mt-36 sm:px-32">
            <Box
                component="img"
                alt="Logo"
                src={logo}
                sx={{marginBottom: "1rem", width: "3rem", height: "3rem"}}
            />
            <Typography variant="h4" fontWeight="bold" sx={{color: "white"}} gutterBottom>
                Access the <br/> admin portal.
            </Typography>
            <Typography variant="body1" sx={{color: "white"}}>
                This is where you can see how the HealthKey platform is being used.
            </Typography>
        </div>
    </div>;
}

export default OutsideLeftComponent;