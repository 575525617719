import React from "react";
import {
    Box, Button, Typography,
} from "@mui/material";
import OutsideLeftComponent from "./OutsideLeftComponent";
import logoWithText from "../images/Logo (Dark background).png";
import {useNavigate} from "react-router-dom";

export interface OutsideTitleTextComponentProps {
    displayButton: boolean;

    title: string;

    text: string;

    secondText: string;
}
const OutsideTitleTextComponent = (props: OutsideTitleTextComponentProps) => {

    let navigate = useNavigate();

    return (
        <Box className="h-screen flex sm:flex-row flex-col"
             sx={{backgroundImage:
                     {sm: "linear-gradient(180.1deg, #4860E6 55.01%, rgba(217, 217, 217, 0.35) 99.91%)"}}}>
            <OutsideLeftComponent/>
            <div className="block sm:hidden bg-purple w-full py-4 px-4">
                <Box
                    component="img"
                    alt="Logo"
                    src={logoWithText}
                    sx={{width: "190px", height: "45px", display:"block", marginLeft:"auto", marginRight:"auto"}}
                />
            </div>
            <div className="sm:w-2/3 h-full m-auto bg-white sm:rounded-l-3xl border border-white py-5 px-5 w-full sm:py-36 sm:pl-44 sm:pr-96">
                <div className="mb-8">
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                        {props.title}
                    </Typography>

                </div>
                <div className="mb-12">
                    <Typography sx={{width: "100%"}} mt={2} mb={2} variant="body1">{props.text}</Typography>
                    <Typography sx={{width: "100%"}} mt={2} mb={2} variant="body1">{props.secondText}</Typography>
                    {props.displayButton ? (
                        <div className="mt-8">
                            <Button color="secondary" className="w-full sm:w-72" sx={{borderRadius:"9px"}} variant="contained" onClick={() => navigate("/login")}>
                                Login
                            </Button>
                        </div>
                    ) : (<div/>)}

                </div>
            </div>
        </Box>
    );
}

export default OutsideTitleTextComponent;