import React, {useEffect} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import {Box, Button, Typography} from "@mui/material";
import logo from "../../images/logo_no_text.png";
import OutsideLeftComponent from "../../components/OutsideLeftComponent";
import OutsideTitleTextComponent from "../../components/OutsideTitleTextComponent";

function ForgottenPasswordComplete() {

    let navigate = useNavigate();



    return (
        <OutsideTitleTextComponent displayButton={true} text={"Thanks for changing your password. You’re all set to start enjoying HealthKey."} title={"Thanks!"} secondText={""} />
    );
}

export default ForgottenPasswordComplete;