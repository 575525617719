import Header from "../components/Header";
import NavigationTabs from "../components/NavigationTabs";
import {
    Backdrop,
    Box, Button, FormControl, FormControlLabel, MenuItem, Modal,
    Paper, Radio, RadioGroup, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Typography,
} from "@mui/material";
import {toast, ToastContainer} from "react-toastify";
import {useAppSelector} from "../store/hooks";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {HealthCategory, Product} from "../types/types";
import axios from "axios";
import authSlice from "../store/slices/auth";
import provider from "../store/slices/providers";


const Services = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accessToken = useAppSelector(state => state.auth.token);
    const providers = useAppSelector(state => state.providers.providers);

    const [allServices, setAllServices] = useState<Product[]>(new Array<Product>());
    const [editServiceModalIsOpen, setEditServiceModalIsOpen] = useState(false);
    const [selectedService, setSelectedService] = useState<Product | undefined>(undefined);
    const [serviceHealthCategories, setServiceHealthCategories] = useState<Array<HealthCategory>>(new Array());
    const [servicePrice, setServicePrice] = useState("0");
    const [serviceName, setServiceName] = useState("");
    const [selectedServiceCategories, setSelectedServiceCategories] = useState<Array<HealthCategory>>(new Array());
    const [errorMessage, setErrorMessage] = useState("");
    const [createServiceOpen, setCreateServiceOpen] = useState(false);
    const [serviceUrl, setServiceUrl] = useState("");
    const [serviceType, setServiceType] = useState("one_off");
    const [selectedProvider, setSelectedProvider] = useState<string | undefined>(undefined);

    useEffect( () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        readAllServices();
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/healthCategories`, {headers: headers}).then((res) => {
                setServiceHealthCategories(res.data);
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/admin/providers`, {headers: headers})
            .then((res) => {
                dispatch(provider.actions.setProviders(res.data));
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }, [])

    const expandEditServiceModal = (service: Product) => {
        setEditServiceModalIsOpen(true);
        setSelectedService(service);
        setServicePrice(service.price + "");
        setServiceName(service.name);
        let categories = new Array();
        if (service.categories) {
            for (let categoryId of service.categories) {
                categories.push(getHealthCategoryById(categoryId));
            }
        }
        setSelectedServiceCategories(categories);
    }

    const getHealthCategoryById = (id: string) : HealthCategory | undefined => {
        for (let category of serviceHealthCategories) {
            if (category.id === id) {
                return category;
            }
        }
        return undefined;
    }

    const closeEditServiceModal = () => {
        setEditServiceModalIsOpen(false);
        setServicePrice("0");
        setServiceName("");
        setErrorMessage("");
    }

    const selectCategory = (category: HealthCategory) => {
        if (selectedServiceCategories.length >= 5) {
            setErrorMessage("You can only select up to five categories for your service.")
        } else {
            let selectedCategories = new Array();
            for (let alreadySelected of selectedServiceCategories) {
                selectedCategories.push(alreadySelected);
            }
            selectedCategories.push(category);
            setSelectedServiceCategories(selectedCategories);
        }
    }

    const unselectCategory = (category: HealthCategory) => {
        setErrorMessage("");
        let selectedCategories = new Array();
        for (let alreadySelected of selectedServiceCategories) {
            if (alreadySelected.id !== category.id) {
                selectedCategories.push(alreadySelected);
            }
        }
        setSelectedServiceCategories(selectedCategories);
    }

    const isSelectedCategory = (category: HealthCategory) : boolean => {
        if (selectedServiceCategories) {
            for (let selectedCategory of selectedServiceCategories) {
                if (selectedCategory.id === category.id) {
                    return true;
                }
            }
        }
        return false;
    }
    const updateServiceChanges = () => {
        if (selectedService) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            let categories = new Array();
            for (let category of selectedServiceCategories) {
                categories.push(category.id);
            }
            axios
                .patch(`${process.env.REACT_APP_SERVER_API_URL}/products/` + selectedService.id, {name: serviceName, price: servicePrice, categories},{headers: headers}).then((res) => {
                axios
                    .get(`${process.env.REACT_APP_SERVER_API_URL}/products`, {headers: headers}).then((res) => {
                    setAllServices(res.data);
                    toast.success('Your details have been successfully updated.');
                    closeEditServiceModal();
                })
                    .catch((err) => {
                        if (err.response!.status === 401 || err.response!.status === 403) {
                            dispatch(authSlice.actions.logout());
                            navigate("/sessionExpired");
                        }
                    });
            })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }
    }

    const readAllServices = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/products`, {headers: headers}).then((res) => {
            setAllServices(res.data);
        })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }

    const largeModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '1px solid #D9D9D959',
        borderRadius: '10px',
        p: 4,
    };

    const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceType((event.target as HTMLInputElement).value);
    };

    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const createService = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        let subscription : any | null = null;
        if (serviceType === "monthly") {
            subscription = {frequency: "1", frequencyUnit:"months"};
        }
        if (serviceType === "yearly") {
            subscription = {frequency: "1", frequencyUnit:"years"};
        }
        const body = {name: serviceName, price: servicePrice, providerId: selectedProvider, url:serviceUrl, subscription: subscription, externalId: serviceName.replace(" ","") + makeid(10)};
        axios
            .post(`${process.env.REACT_APP_SERVER_API_URL}/products`, body, {headers: headers})
            .then((res) => {
                readAllServices();
                setCreateServiceOpen(false);
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }

    return (
        <Box sx={{background:"#F5F5F5"}}>
            <Header />
            <NavigationTabs selectedTab={"3"} />
            <Box sx={{paddingLeft: {
                    xs:"16px",
                    sm:"50px"
                }, paddingRight: {
                    xs: "16px",
                    sm: "50px"
                }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">

                <div className="bg-white rounded-lg border-grey shadow-md py-4 px-4 sm:py-8 sm:px-8 w-full">
                    <div className="w-full flex flex-row-reverse p-4">
                        <Button variant="contained" onClick={() => setCreateServiceOpen(true)}>Create new service</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Service name</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Categories</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allServices.map((product) => (
                                    <TableRow
                                        key={product.externalId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {product.name}
                                        </TableCell>
                                        <TableCell align="right">£{product.price}</TableCell>
                                        <TableCell>
                                            <div className="flex flex-row-reverse flex-wrap">
                                                {product.categories.map((category) => (
                                                    <div key={category}>
                                                        <Box sx={{borderRadius: "10px", background:"#E9F5E3", color:"#242426", padding:"5px 10px 5px 10px", margin:{xs:"8px 0px 8px 0px", sm:"8px 0px 8px 16px"}}}>
                                                            <Typography variant="body2" align="center" color="#27104E" >
                                                                {getHealthCategoryById(category)?.name}
                                                            </Typography>
                                                        </Box>
                                                    </div>
                                                ))}
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Button variant="contained" onClick={() => {expandEditServiceModal(product);}}>
                                                Edit Service
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
            <Modal
                open={editServiceModalIsOpen}
                onClose={closeEditServiceModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}>
                <Box sx={largeModalStyle}>
                    <div className="flex flex-row mb-8">
                        <div className="w-4/5 mr-8">
                            <TextField
                                id="serviceName"
                                name="serviceName"
                                label="Name"
                                variant="standard"
                                margin="normal"
                                type="text"
                                fullWidth
                                sx={visibleTextfield}
                                value={serviceName}
                                onChange={e => setServiceName(e.target.value)}
                            />
                        </div>
                        <div className="w-1/5">
                            <TextField
                                id="servicePrice"
                                name="servicePrice"
                                label="Price"
                                variant="standard"
                                margin="normal"
                                type="number"
                                fullWidth
                                sx={visibleTextfield}
                                value={servicePrice}
                                onChange={e => setServicePrice(e.target.value)}
                                InputProps={{
                                    startAdornment: <span style={{paddingRight: "4px"}}>{'£'}</span>
                                }}
                            />
                        </div>
                    </div>
                    <Typography variant="body1" gutterBottom>Please select up to three health categories:</Typography>
                    <div className="flex flex-row flex-wrap">
                        {serviceHealthCategories.map((category) => (
                            <div key={category.htmlId}>
                                {isSelectedCategory(category) ? (
                                    <Box sx={{borderRadius: "10px", background:"#E9F5E3", color:"#242426", padding:"5px 10px 5px 10px", margin:{xs:"8px 0px 8px 0px", sm:"8px 16px 8px 0px"}}} onClick={() => unselectCategory(category)}>
                                        <Typography variant="body2" align="center" color="#27104E" >
                                            {category.name}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{borderRadius: "10px", background:"#FCF0F8", color:"#242426", padding:"5px 10px 5px 10px", margin:{xs:"8px 0px 8px 0px", sm:"8px 16px 8px 0px"}}} onClick={() => selectCategory(category)}>
                                        <Typography variant="body2" align="center" color="#27104E" >
                                            {category.name}
                                        </Typography>
                                    </Box>
                                )}
                            </div>
                        ))}
                    </div>
                    <div>
                        <Typography variant="body1" color="error">{errorMessage}</Typography>
                    </div>
                    <Box sx={{marginTop: "16px;"}}>
                        <Button color="secondary" variant="contained" type="submit" onClick={() => updateServiceChanges()}>
                            Save Changes
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={createServiceOpen}
                onClose={() => setCreateServiceOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}>
                <Box sx={largeModalStyle}>
                    <div className="flex flex-row">
                        <div className="w-full">
                            <div className="flex flex-row">
                                <div className="w-4/5 mr-8">
                                    <TextField
                                        id="serviceName"
                                        name="serviceName"
                                        label="Name"
                                        variant="standard"
                                        margin="normal"
                                        type="text"
                                        fullWidth
                                        sx={visibleTextfield}
                                        value={serviceName}
                                        onChange={(e) => setServiceName(e.target.value)}
                                    />
                                </div>
                                <div className="w-1/5">
                                    <TextField
                                        id="servicePrice"
                                        name="servicePrice"
                                        label="Price"
                                        variant="standard"
                                        margin="normal"
                                        type="number"
                                        fullWidth
                                        sx={visibleTextfield}
                                        value={servicePrice}
                                        onChange={(e) => setServicePrice(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <span style={{ paddingRight: "4px" }}>{"£"}</span>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <TextField
                                id="serviceUrl"
                                name="serviceUrl"
                                label="URL"
                                variant="standard"
                                margin="normal"
                                type="text"
                                fullWidth
                                sx={visibleTextfield}
                                value={serviceUrl}
                                onChange={(e) => setServiceUrl(e.target.value)}
                            />
                            <FormControl sx={{padding:"16px 0px"}}>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={serviceType}
                                    row
                                    onChange={handleTypeChange}
                                >
                                    <FormControlLabel
                                        value="one_off"
                                        control={<Radio />}
                                        label="One-time purchase"
                                    />
                                    <FormControlLabel
                                        value="monthly"
                                        control={<Radio />}
                                        label="Monthly subscription"
                                    />
                                    <FormControlLabel
                                        value="yearly"
                                        control={<Radio />}
                                        label="Yearly"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <div className="flex flex-col gap-4 w-full">
                                <Typography variant="body1">Provider</Typography>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedProvider}
                                    size="small"
                                    onChange={(e) => setSelectedProvider(e.target.value)}
                                >
                                    {providers.map((provider, index) => (
                                        <MenuItem key={index} value={provider.id}>
                                            {provider.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="py-8 flex flex-row-reverse">
                                <Button variant="contained" onClick={createService}>Create service</Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeButton={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
                theme="colored"
            />
        </Box>
    )
}

export default Services;