import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {Link, useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import {Alert, Box, Button, Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import logo from "../../images/logo_no_text.png";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import userDetails from "../../store/slices/userDetails";
import OutsideLeftComponent from "../../components/OutsideLeftComponent";
import logoWithText from "../../images/Logo (Dark background).png";
import {toast, ToastContainer} from "react-toastify";

function ForgottenPasswordEmail() {
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();


    const handleSendPwdResetEmail = (email: string) => {
        let body =  { email };
        axios
            .post(`${process.env.REACT_APP_API_URL}/password_reset/`, body)
            .then((res) => {
                axios
                    .patch(`${process.env.REACT_APP_SERVER_API_URL}/users/sendPasswordResetEmail/` + email)
                    .then((res) => {
                        setLoading(false);
                        navigate("/forgottenPasswordEmailSent");
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                        setLoading(false);
                    });

            })
            .catch((err) => {
                toast.error(err.response.data);
                setLoading(false);
            });
    };

    const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };

    const formik = useFormik({
        initialValues: {
            email: ""
        },

        onSubmit: (values) => {
            setLoading(true);
            handleSendPwdResetEmail(values.email);
        },
        validationSchema: Yup.object({
            email: Yup.string().trim().email('Invalid email address').required("The email is required."),
        }),
    });

    return (
        <Box className="flex sm:flex-row flex-col h-screen"
             sx={{backgroundImage:
                     {sm: "linear-gradient(180.1deg, #4860E6 55.01%, rgba(72, 96, 230, 0.35) 99.91%)"}}}>
            <OutsideLeftComponent/>
            <div className="block sm:hidden bg-purple w-full py-4 px-4">
                <Box
                    component="img"
                    alt="Logo"
                    src={logoWithText}
                    sx={{width: "190px", height: "45px", display:"block", marginLeft:"auto", marginRight:"auto"}}
                />
            </div>
            <div className="sm:w-2/3 h-full m-auto bg-white sm:rounded-l-3xl border border-white py-5 px-5 w-full sm:py-36 sm:pl-44 sm:pr-96">
                <div className="mb-4">
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                        Reset your password
                    </Typography>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="space-y-4">
                        <TextField
                            id="email"
                            name="email"
                            label="Email"
                            variant="standard"
                            margin="normal"
                            sx={visibleTextfield}
                            required
                            fullWidth
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </div>


                    <div className="mt-8">
                        <Button color="secondary" className="w-full sm:w-72" sx={{ borderRadius:"9px"}} variant="contained" type="submit" disabled={loading}>
                            Send password reset email
                        </Button>
                    </div>
                </form>
            </div>
            <ToastContainer
                position="bottom-center"
                // autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                closeButton={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
                theme="colored"
            />
        </Box>
    );
}

export default ForgottenPasswordEmail;