import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import NavigationTabs from "../components/NavigationTabs";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../store/hooks";
import {useFormik} from "formik";

import * as Yup from "yup";
import axios from "axios";
import {
    Backdrop,
    Box,
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List,
    ListItem, Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TableSortLabel, TextField, Tooltip,
    Typography
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';


import {useNavigate} from "react-router-dom";
import authSlice from "../store/slices/auth";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../components/Footer";
import employer from "../store/slices/employer";
import {UserForDeletion, EmployerForAdmin, HeadCellEmployers} from "../types/types";

import {DeleteOutlined, SearchOutlined, ThumbUp} from "@mui/icons-material";
import { CSVLink } from 'react-csv';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | boolean },
    b: { [key in Key]: number | string | boolean},
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const Employers = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loggedInUser = useAppSelector(state => state.userDetails.user);
    const accessToken = useAppSelector(state => state.auth.token);
    const employers = useAppSelector(state => state.employer.employers);

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof EmployerForAdmin>('name');
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState<EmployerForAdmin[]>(new Array<EmployerForAdmin>());
    const [searchQuery, setSearchQuery] = useState("");
    const [csvData, setCsvData] = useState(new Array<any>());


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof EmployerForAdmin,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    const headCells: readonly HeadCellEmployers[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Employer name',
        },
        {
            id: 'numberOfUsers',
            numeric: true,
            disablePadding: false,
            label: 'Number of users',
        },
        {
            id: 'employerTopUp',
            numeric: true,
            disablePadding: false,
            label: 'Money allocated'
        },
        {
            id: 'userTopUp',
            numeric: true,
            disablePadding: false,
            label: 'Money added by users',
        },
        {
            id: 'totalMoneySpent',
            numeric: true,
            disablePadding: false,
            label: 'Money spent',
        },
        {
            id: 'totalMoneySpentLastMonth',
            numeric: true,
            disablePadding: false,
            label: 'Last month spent',
        },
        {
            id: 'totalMoneySpentThisMonth',
            numeric: true,
            disablePadding: false,
            label: 'This month spent',
        },
        {
            id: 'published',
            numeric: false,
            disablePadding: false,
            label: 'Published',
        }
    ];


    useEffect(() => {
        if (loggedInUser) {
            readAllEmployers();
        }
    }, [loggedInUser, accessToken, dispatch])

    const readAllEmployers = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/admin/employers`, {headers: headers})
            .then((res) => {
                dispatch(employer.actions.setEmployers(res.data));
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }


    useEffect(() => {
        if (employers) {
            if (searchQuery === "") {
                setRows(employers);
                setExportCSVData(employers);
            } else {
                const filtered = employers.filter((e) => {
                    return (
                        e.name.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                });
                setRows(filtered);
                setExportCSVData(filtered);
            }
        }
    }, [searchQuery, employers]);

    const setExportCSVData = (listOfEmployers : EmployerForAdmin[]) => {
        let data = new Array<any>();
        const headers = ["Name", "Number of users", "Money allocated", "Money spent", "Last month spent", "This month spent"];
        data.push(headers);
        for (let e of listOfEmployers) {
            data.push([e.name, e.numberOfUsers, e.employerTopUp, e.totalMoneySpent, e.totalMoneySpentLastMonth, e.totalMoneySpentThisMonth]);
        }
        setCsvData(data);
    }

    const publishEmployer = (employerId : string) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios
            .patch(`${process.env.REACT_APP_SERVER_API_URL}/admin/employers/` + employerId + "/publish", null,{headers: headers})
            .then((res) => {
                readAllEmployers();
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }

        const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };


    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #D9D9D959',
        borderRadius: '10px',
        p: 4,
    };

    interface EnhancedTableProps {
        numSelected: number;
        onRequestSort: (event: React.MouseEvent<unknown>, property: keyof EmployerForAdmin) => void;
        order: Order;
        orderBy: string;
        rowCount: number;
    }

    function EnhancedTableHead(props: EnhancedTableProps) {
        const { order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler =
            (property: keyof EmployerForAdmin) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <TableHead sx={{background:"#4860E6"}}>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            sx={{color:"white"}}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                sx = {
                                    {
                                        '&.MuiTableSortLabel-root': {
                                            color: 'white',
                                        },
                                        '&.MuiTableSortLabel-root:hover': {
                                            color: '#F5F5F5',
                                            fontWeight: 'bold'
                                        },
                                        '&.Mui-active': {
                                            color: 'white',
                                            fontWeight: 'bold'
                                        },
                                        '& .MuiTableSortLabel-icon': {
                                            color: 'white !important',
                                        },
                                    }
                                }

                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Box sx={{background:"#F5F5F5"}}>
            <Header />
            <NavigationTabs selectedTab={"1"} />
            <Box sx={{paddingLeft: {
                    xs:"16px",
                    sm:"50px"
                }, paddingRight: {
                    xs: "16px",
                    sm: "50px"
                }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">

                <div className="bg-white rounded-lg border-grey shadow-md py-4 px-4 sm:py-8 sm:px-8 w-full">
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginBottom:"1rem"}}>
                        <Button variant="contained" color="secondary" sx={{marginRight:"1rem"}}>
                            <CSVLink filename={"employers_on_HealthKey.csv"} data={csvData}>Download table</CSVLink>
                        </Button>
                        <form>
                            <TextField
                                id="search-bar"
                                className="text"
                                onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                }}
                                variant="outlined"
                                placeholder="Search by name or email"
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: 30
                                        },
                                        '& input': {
                                            width:"200px",
                                            fontSize:'12px',
                                            fontColor:'#6C6363'
                                        },
                                        background: "#F0F0F0",
                                        borderRadius: 30,
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#6C6363',
                                        }
                                    }
                                }}
                                InputProps={{
                                    startAdornment:<IconButton type="submit" aria-label="search">
                                        <SearchOutlined style={{ fill: "#6C6363" }} />
                                    </IconButton>
                                }}
                            />

                        </form>
                    </Box>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((employer, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, employer.name)}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={employer.name}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="normal"
                                                >
                                                    {employer.name}
                                                </TableCell>
                                                <TableCell align="right">{employer.numberOfUsers}</TableCell>
                                                <TableCell align="right">{employer.employerTopUp}</TableCell>
                                                <TableCell align="right">{employer.userTopUp}</TableCell>
                                                <TableCell align="right">{employer.totalMoneySpent}</TableCell>
                                                <TableCell align="right">{employer.totalMoneySpentLastMonth}</TableCell>
                                                <TableCell align="right">{employer.totalMoneySpentThisMonth}</TableCell>
                                                <TableCell align="right">{employer.published ? "yes" : "no"}</TableCell>
                                                <div className="flex flex-row">
                                                <IconButton
                                                    onClick={() =>
                                                        publishEmployer(employer.id)
                                                    }
                                                    color="primary"
                                                >
                                                    <Tooltip
                                                        id="button-report"
                                                        title="Publish employer"
                                                    >
                                                        <ThumbUp
                                                            color="secondary"
                                                            fontSize="small"
                                                        />
                                                    </Tooltip>
                                                </IconButton>
                                                </div>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Box>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Footer displayLogo={true} selectedTab={"0"}/>
        </Box>
    );
};

export default Employers;