import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {Link, useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import {Alert, Box, Button, Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import logo from "../../images/logo_no_text.png";
import {toast, ToastContainer} from "react-toastify";
import OutsideLeftComponent from "../../components/OutsideLeftComponent";
import logoWithText from "../../images/Logo (Dark background).png";

function ForgottenPasswordChangePassword() {
    let {resetToken} = useParams();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [tokenValid, setTokenValid] = useState(true);
    let navigate = useNavigate();


    useEffect(() => {
        if (resetToken) {
            let body = {
                token: resetToken
            };
            axios
                .post(`${process.env.REACT_APP_API_URL}/password_reset/validate_token/`, body)
                .then((res) => {
                })
                .catch((err) => {
                    setTokenValid(false);
                });
        }
    }, [resetToken])

    const handleChangePassword = (password: string) => {
        let body = {
            token: resetToken,
            password: password
        };
        axios.post(`${process.env.REACT_APP_API_URL}/password_reset/confirm/`, body)
            .then((res) => {
                setLoading(false);
                navigate("/forgottenPasswordComplete");
            })
            .catch((err) => {
                if (err.response.data.password) {
                    toast.error(err.response.data.password[0], {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                setLoading(false);
            });
    };

    const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },

        onSubmit: (values) => {
            setLoading(true);
            handleChangePassword(values.password);
        },
        validationSchema: Yup.object({
           password: Yup.string().trim().required("The password is required")
                .min(8, "Your password must be longer than 8 characters.")
                .max(50, "Your password mustn't be longer than 50 characters.")
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])/,
                    "Your password must contain at least one uppercase and one lowercase character."
                )
                .matches(/^(?=.{6,20}$)\D*\d/, "Your password must contain at least one digit."),
            confirmPassword: Yup.string()
                .test('passwords-match', 'Passwords must match', function (value) {
                    return this.parent.password === value
                })
        }),
    });

    return (
        <Box className="flex sm:flex-row flex-col h-screen"
             sx={{backgroundImage:
                     {sm: "linear-gradient(180.1deg, #4860E6 55.01%, rgba(217, 217, 217, 0.35) 99.91%)"}}}>
            <OutsideLeftComponent/>
            <div className="block sm:hidden bg-purple w-full py-4 px-4">
                <Box
                    component="img"
                    alt="Logo"
                    src={logoWithText}
                    sx={{width: "190px", height: "45px", display:"block", marginLeft:"auto", marginRight:"auto"}}
                />
            </div>
            <div className="sm:w-2/3 h-full m-auto bg-white sm:rounded-l-3xl border border-white py-5 px-5 w-full sm:py-36 sm:pl-44 sm:pr-96">
                    <div className="mb-4">
                        <Typography variant="h5" fontWeight="bold" gutterBottom>
                            Reset your password
                        </Typography>
                    </div>
                    {tokenValid ? (
                <form onSubmit={formik.handleSubmit}>
                    <div className="space-y-4">
                        <TextField
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            variant="standard"
                            margin="normal"
                            required
                            sx={visibleTextfield}
                            fullWidth
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <TextField
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            sx={visibleTextfield}
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        />
                        {message ? <Alert severity="error">{message}</Alert> : <></> }
                    </div>


                    <div className="mt-8">
                        <Button color="secondary" className="w-full sm:w-72" sx={{borderRadius:"9px"}} variant="contained" type="submit" disabled={loading}>
                            Change password
                        </Button>
                    </div>
                </form>) : (
                        <Typography sx={{width: "100%"}} mt={2} mb={2} variant="body1">The link doesn't seem to be valid. Please try again.</Typography>
                    )}
                </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </Box>
    );
}

export default ForgottenPasswordChangePassword;