import axios from "axios";

const removeImage = async (
  accessToken: string | null,
  type: string,
): Promise<any | undefined> => {
  const headers = {
    Authorization: "Bearer " + accessToken,
  };

  return await axios.delete(
    `${process.env.REACT_APP_SERVER_API_URL}/providers/removeImage/` + type,
    {
      headers,
    },
  );
};

const uploadContentImage = async (
  file: File,
  accessToken: string | null,
  contentId: string,
): Promise<any | undefined> => {
  let formData = new FormData();

  formData.append("file", file);

  const headers = {
    Authorization: "Bearer " + accessToken,
    "Content-Type": "multipart/form-data",
  };

  return await axios.post(
    `${process.env.REACT_APP_SERVER_API_URL}/contents/` +
      contentId +
      `/uploadImage`,
    formData,
    {
      headers,
    },
  );
};

const FileUploadService = {
  uploadContentImage,
  removeImage,
};

export default FileUploadService;
