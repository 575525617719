import React, {useState} from "react";
import {
    BottomNavigation, BottomNavigationAction,
    Box, Paper, Typography,
} from "@mui/material";
import logo from "../images/Logo (Light background).png";
import {
    AccountCircle,
    Home,
    Payments,
    Savings,
    Subscriptions
} from "@mui/icons-material";
import {Link} from "react-router-dom";

export interface FooterProps {
    displayLogo: boolean;

    selectedTab: string;
}
const Footer = (props: FooterProps) => {

    const [value, setValue] = useState(props.selectedTab);

    return (
        <footer>
            <Box className="hidden sm:block" sx={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"3rem"}}>
                {props.displayLogo ? (<Box className="hidden sm:block"
                    component="img"
                    alt="Logo"
                    src={logo}
                    sx={{marginBottom:"1rem", width:"190px", height:"45px"}}
                />) : (<div/>)}
                <Typography className="hidden sm:block" variant="body2" gutterBottom>Copyright © 2023 HealthKey</Typography>
                <Box  sx={{display:"flex", flexDirection:"row", marginBottom:"1rem"}}>
                    <Typography className="hidden sm:block" variant="body2" gutterBottom>
                        <a className="underline" href="https://www.healthkey.health/terms-of-use" rel="noreferrer" target="_blank">Terms of use </a>
                    </Typography>
                    <Typography className="hidden sm:block" variant="body2" sx={{paddingRight:"10px", paddingLeft:"10px"}} gutterBottom> | </Typography>
                    <Typography className="hidden sm:block" variant="body2">
                        <a href="https://www.healthkey.health/privacy-policy" className="underline" rel="noreferrer" target="_blank">Privacy Policy</a>
                    </Typography>
                </Box>
            </Box>
            <Paper className="block sm:hidden" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Home" icon={<Home/>} value={"0"} component={Link} to={"/"} />
                    <BottomNavigationAction label="Budget" icon={<Savings />} value={"1"} component={Link} to={"/healthBudget"}/>
                    <BottomNavigationAction label="Purchases" icon={<Payments />} value={"2"} component={Link} to={"/purchaseHistory"}/>
                    <BottomNavigationAction label="Subscriptions" icon={<Subscriptions />} value={"3"} component={Link} to={"/subscriptions"}/>
                    <BottomNavigationAction label="Account" icon={<AccountCircle />} value={"5"} component={Link} to={"/myAccount"}/>
                </BottomNavigation>
            </Paper>
        </footer>
    );
}

export default Footer;