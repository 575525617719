import React from "react";
import {Navigate} from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import {useSearchParams} from "react-router-dom";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const auth = useSelector((state: RootState) => state.auth);

    const [searchParams, setSearchParams] = useSearchParams();

    const response_type = searchParams.get('response_type')
    const client_id = searchParams.get('client_id')
    const redirect_uri = searchParams.get('redirect_uri')
    const scope = searchParams.get('scope')
    const code_challenge = searchParams.get('code_challenge')
    const code_challenge_method = searchParams.get('code_challenge_method')

    if (!auth.account) {
        if (response_type) {
            let path = "/login?";
            path = path + "response_type=" + response_type + "&";
            path = path + "client_id=" + client_id + "&";
            path = path + "redirect_uri=" + redirect_uri + "&";
            path = path + "scope=" + scope + "&";
            path = path + "code_challenge=" + code_challenge + "&";
            path = path + "code_challenge_method=" + code_challenge_method + "&";
            return <Navigate to={path} />;
        }
        return <Navigate to="/login" />;
    }
    return children;
};

export default ProtectedRoute;