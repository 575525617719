import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import NavigationTabs from "../components/NavigationTabs";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../store/hooks";
import {useFormik} from "formik";

import * as Yup from "yup";
import axios from "axios";
import {
    Backdrop,
    Box,
    Button, FormControl, FormControlLabel, IconButton, MenuItem, Modal, Radio, RadioGroup, Select, SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TableSortLabel, TextField, Tooltip, Typography
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';


import {useNavigate} from "react-router-dom";
import authSlice from "../store/slices/auth";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../components/Footer";
import provider from "../store/slices/providers";
import {
    ProviderForAdmin,
    HeadCellProviders, HealthCategory
} from "../types/types";

import {
    PeopleRounded,
    SearchOutlined,
    SupervisedUserCircleOutlined,
    ThumbUp,
    VerifiedUserRounded
} from "@mui/icons-material";
import { CSVLink, CSVDownload } from 'react-csv';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const Providers = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loggedInUser = useAppSelector(state => state.userDetails.user);
    const accessToken = useAppSelector(state => state.auth.token);
    const providers = useAppSelector(state => state.providers.providers);

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof ProviderForAdmin>('name');
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState<ProviderForAdmin[]>(new Array<ProviderForAdmin>());
    const [searchQuery, setSearchQuery] = useState("");
    const [csvData, setCsvData] = useState(new Array<any>());
    const [providerHealthCategories, setProviderHealthCategories] = useState<Array<HealthCategory>>(new Array());
    const [createProviderModalOpen, setCreateProviderModalOpen] = useState(false);
    const [healthCategory, setHealthCategory] = useState<string | undefined>(undefined);
    const [providerType, setProviderType] = useState("full_integration");
    const [providerAdminsOpen, setProviderAdminsOpen] = useState(false);
    const [providerForAdmin, setProviderForAdmin] = useState<string | undefined>(undefined);
    const [admins, setAdmins] = useState<string[]>([]);
    const [adminEmail, setAdminEmail] = useState("");
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof ProviderForAdmin,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    const getHealthCategoryName = (id: string) : string | undefined => {
        let categoryName;
        if (providerHealthCategories) {
            for (let category of providerHealthCategories) {
                if (category.id === id) {
                    return category.name
                }
            }
        }
        return categoryName;
    }

    const headCells: readonly HeadCellProviders[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Provider name',
        },
        {
            id: 'numberOfPurchases',
            numeric: true,
            disablePadding: false,
            label: 'Purchases',
        },
        {
            id: 'totalMoneySpent',
            numeric: true,
            disablePadding: false,
            label: 'Money spent',
        },
        {
            id: 'totalMoneySpentLastMonth',
            numeric: true,
            disablePadding: false,
            label: 'Last month spent',
        },
        {
            id: 'totalMoneySpentThisMonth',
            numeric: true,
            disablePadding: false,
            label: 'This month spent',
        },
        {
            id: 'healthCategoryId',
            numeric: false,
            disablePadding: false,
            label: 'Health category',
        }
    ];


    useEffect(() => {
        if (loggedInUser) {
           readProviders();
           readHealthCategories();

        }
    }, [loggedInUser, accessToken, dispatch])


    useEffect(() => {
        if (providers) {
            if (searchQuery === "") {
                setRows(providers);
                setExportCSVData(providers);
            } else {
                const filtered = providers.filter((e) => {
                    return (
                        e.name.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                });
                setRows(filtered);
                setExportCSVData(filtered);
            }
        }
    }, [searchQuery, providers]);

    const setExportCSVData = (listOfProviders : ProviderForAdmin[]) => {
        let data = new Array<any>();
        const headers = ["Name", "Number of purchases", "Total spent", "Last month spent", "This month spent", "Health category"];
        data.push(headers);
        for (let p of listOfProviders) {
            data.push([p.name, p.numberOfPurchases, p.totalMoneySpent, p.totalMoneySpentLastMonth, p.totalMoneySpentThisMonth, getHealthCategoryName(p.healthCategoryId)]);
        }
        setCsvData(data);
    }

    const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };



    // const formikEmployerDetails = useFormik({
    //     initialValues: {
    //         name: ""
    //     },
    //     enableReinitialize: true,
    //
    //     onSubmit: (values) => {
    //         handleCreateEmployer(values.name);
    //     },
    //     validationSchema: Yup.object({
    //         name: Yup.string().trim().min(3, 'Must be 3 characters or more').required("The name is required.")
    //     }),
    //
    // });

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height:600,
        bgcolor: 'background.paper',
        border: '1px solid #D9D9D959',
        borderRadius: '10px',
        p: 2,
    };

    interface EnhancedTableProps {
        numSelected: number;
        onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ProviderForAdmin) => void;
        order: Order;
        orderBy: string;
        rowCount: number;
    }

    function EnhancedTableHead(props: EnhancedTableProps) {
        const { order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler =
            (property: keyof ProviderForAdmin) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <TableHead sx={{background:"#4860E6"}}>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            sx={{color:"white"}}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                sx = {
                                    {
                                        '&.MuiTableSortLabel-root': {
                                            color: 'white',
                                        },
                                        '&.MuiTableSortLabel-root:hover': {
                                            color: '#F5F5F5',
                                            fontWeight: 'bold'
                                        },
                                        '&.Mui-active': {
                                            color: 'white',
                                            fontWeight: 'bold'
                                        },
                                        '& .MuiTableSortLabel-icon': {
                                            color: 'white !important',
                                        },
                                    }
                                }

                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    const handleCategoryChange = (event: SelectChangeEvent, providerId : string) => {
        const body = {healthCategoryId: event.target.value as string};

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios
            .patch(`${process.env.REACT_APP_SERVER_API_URL}/providers/` + providerId, body, {headers: headers})
            .then((res) => {
                readProviders();
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    };

    const readHealthCategories = () => {
        if (loggedInUser) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/providerHealthCategories`, {headers: headers})
                .then((res) => {
                    setProviderHealthCategories(res.data);
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }

    }

    const readProviders = () => {
        if (loggedInUser) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/admin/providers`, {headers: headers})
                .then((res) => {
                    dispatch(provider.actions.setProviders(res.data));
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }
    }

    const createProvider = (name: string | undefined, shortDescription: string | undefined, longDescription: string | undefined, url: string | undefined) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        const body = {name: name, shortDescription: shortDescription, longDescription: longDescription, url:url, healthCategoryId: healthCategory, type: providerType};
        axios
            .post(`${process.env.REACT_APP_SERVER_API_URL}/providers`, body, {headers: headers})
            .then((res) => {
                readProviders();
                setCreateProviderModalOpen(false);
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }

    useEffect(() => {
        if (providerForAdmin) {
            readAdminsForProvider(providerForAdmin);
        }
    }, [providerForAdmin, dispatch])

    const readAdminsForProvider = (providerId: string) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/providers/` + providerId + '/admins', {headers: headers})
            .then((res) => {
                setAdmins(res.data);
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }

    const addNewAdmin = () => {
        if (providerForAdmin) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .post(`${process.env.REACT_APP_SERVER_API_URL}/providers/` + providerForAdmin + '/admins/' + adminEmail, null, {headers: headers})
                .then((res) => {
                    readAdminsForProvider(providerForAdmin);
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }
    }

    const formikProviderDetails = useFormik({
        initialValues: {
            name: "",
            shortDescription: "",
            longDescription: "",
            url: "",
        },

        onSubmit: (values) => {
            createProvider(
                values.name,
                values.shortDescription,
                values.longDescription,
                values.url,
            );
        },
    });

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProviderType((event.target as HTMLInputElement).value);
    };

    const publishProviderForWIP = (providerId: string) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios
            .post(`${process.env.REACT_APP_SERVER_API_URL}/admin/providers/` + providerId + "/employers/" + process.env.REACT_APP_INTEGRATION_EMPLOYER_ID, null, {headers: headers})
            .then((res) => {
                readProviders();
                setCreateProviderModalOpen(false);
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }

    return (
        <Box sx={{background:"#F5F5F5"}}>
            <Header />
            <NavigationTabs selectedTab={"2"} />
            <Box sx={{paddingLeft: {
                    xs:"16px",
                    sm:"50px"
                }, paddingRight: {
                    xs: "16px",
                    sm: "50px"
                }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">

                <div className="bg-white rounded-lg border-grey shadow-md py-4 px-4 sm:py-8 sm:px-8 w-full">
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginBottom:"1rem"}}>
                        <Button variant="outlined" color="secondary" sx={{marginRight:"1rem"}} onClick={() => setCreateProviderModalOpen(true)}>
                            Create new provider
                        </Button>
                        <Button variant="contained" color="secondary" sx={{marginRight:"1rem"}}>
                            <CSVLink filename={"providers_on_HealthKey.csv"} data={csvData}>Download table</CSVLink>
                        </Button>
                        <form>
                            <TextField
                                id="search-bar"
                                className="text"
                                onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                }}
                                variant="outlined"
                                placeholder="Search by name or email"
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: 30
                                        },
                                        '& input': {
                                            width:"200px",
                                            fontSize:'12px',
                                            fontColor:'#6C6363'
                                        },
                                        background: "#F0F0F0",
                                        borderRadius: 30,
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#6C6363',
                                        }
                                    }
                                }}
                                InputProps={{
                                    startAdornment:<IconButton type="submit" aria-label="search">
                                        <SearchOutlined style={{ fill: "#6C6363" }} />
                                    </IconButton>
                                }}
                            />

                        </form>
                    </Box>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((provider, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, provider.name)}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={provider.name}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="normal"
                                                >
                                                    {provider.name}
                                                </TableCell>
                                                <TableCell align="right">{provider.numberOfPurchases}</TableCell>
                                                <TableCell align="right">{provider.totalMoneySpent}</TableCell>
                                                <TableCell align="right">{provider.totalMoneySpentLastMonth}</TableCell>
                                                <TableCell align="right">{provider.totalMoneySpentThisMonth}</TableCell>
                                                <div className="w-full flex flex-row">
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={provider.healthCategoryId}
                                                        size="small"
                                                        onChange={e => handleCategoryChange(e, provider.id)}
                                                        sx={{width:"100%"}}
                                                    >
                                                        {providerHealthCategories.map((category, index) => (
                                                            <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    <IconButton
                                                        onClick={() =>
                                                            publishProviderForWIP(provider.id)
                                                        }
                                                        color="primary"
                                                    >
                                                        <Tooltip
                                                            id="button-report"
                                                            title="Publish provider to be configured"
                                                        >
                                                            <ThumbUp
                                                                color="secondary"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            setProviderAdminsOpen(true);
                                                            setProviderForAdmin(provider.id);}
                                                        }
                                                        color="primary"
                                                    >
                                                        <Tooltip
                                                            id="button-report"
                                                            title="Edit provider's admins"
                                                        >
                                                            <PeopleRounded
                                                                color="secondary"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </IconButton>
                                                </div>

                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Box>

            <Modal
                open={createProviderModalOpen}
                onClose={() => setCreateProviderModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}>
                <Box sx={modalStyle}>
                    <form
                        style={{ width: "100%" }}
                        onSubmit={formikProviderDetails.handleSubmit}
                    >
                            <div className="flex flex-col w-full gap-2">
                                <div className="flex flex-row gap-2">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name"
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                        required
                                        sx={visibleTextfield}
                                        value={formikProviderDetails.values.name}
                                        onChange={formikProviderDetails.handleChange}
                                        onBlur={formikProviderDetails.handleBlur}
                                        error={
                                            formikProviderDetails.touched.name &&
                                            Boolean(formikProviderDetails.errors.name)
                                        }
                                        helperText={
                                            formikProviderDetails.touched.name &&
                                            formikProviderDetails.errors.name
                                        }
                                    />
                                    <TextField
                                        id="url"
                                        name="url"
                                        label="URL"
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                        sx={visibleTextfield}
                                        value={formikProviderDetails.values.url}
                                        onChange={formikProviderDetails.handleChange}
                                        onBlur={formikProviderDetails.handleBlur}
                                        error={
                                            formikProviderDetails.touched.url &&
                                            Boolean(formikProviderDetails.errors.url)
                                        }
                                        helperText={
                                            formikProviderDetails.touched.url &&
                                            formikProviderDetails.errors.url
                                        }
                                    />
                                </div>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={healthCategory}
                                    size="small"
                                    onChange={(e) => setHealthCategory(e.target.value)}
                                >
                                    {providerHealthCategories.map((category, index) => (
                                        <MenuItem key={index} value={category.id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={providerType}
                                        row
                                        onChange={handleTypeChange}
                                    >
                                        <FormControlLabel
                                            value="full_integration"
                                            control={<Radio />}
                                            label="Full integration"
                                        />
                                        <FormControlLabel
                                            value="code"
                                            control={<Radio />}
                                            label="Code"
                                        />
                                        <FormControlLabel
                                            value="deeplink"
                                            control={<Radio />}
                                            label="Deeplink"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <TextField
                                    id="shortDescription"
                                    label="One liner (max 80 characters)"
                                    name="shortDescription"
                                    variant="standard"
                                    required
                                    multiline
                                    inputProps={{
                                        maxLength: 80,
                                    }}
                                    value={formikProviderDetails.values.shortDescription}
                                    onChange={formikProviderDetails.handleChange}
                                    onBlur={formikProviderDetails.handleBlur}
                                    error={
                                        formikProviderDetails.touched.shortDescription &&
                                        Boolean(formikProviderDetails.errors.shortDescription)
                                    }
                                    helperText={
                                        formikProviderDetails.touched.shortDescription &&
                                        formikProviderDetails.errors.shortDescription
                                    }
                                    sx={{ width: "100%", marginBottom: "2rem" }}
                                    rows={2}
                                />
                                <TextField
                                    id="longDescription"
                                    label="Longer description (max 2000 characters)"
                                    variant="standard"
                                    multiline
                                    required
                                    inputProps={{
                                        maxLength: 2000,
                                    }}
                                    value={formikProviderDetails.values.longDescription}
                                    onChange={formikProviderDetails.handleChange}
                                    onBlur={formikProviderDetails.handleBlur}
                                    rows={8}
                                />
                            </div>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                            }}
                        >
                            <div className="flex justify-center items-center mt-6">
                                <Button
                                    variant="contained"
                                    type="submit"
                                >
                                    Create provider
                                </Button>
                            </div>
                        </Box>
                    </form>
                </Box>
            </Modal>

            <Modal
                open={providerAdminsOpen}
                onClose={() => {setProviderAdminsOpen(false); setProviderForAdmin(undefined)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}>
                <Box sx={modalStyle}>
                    <div className="w-full flex flex-col gap-4">
                        <Typography variant="h6">List of admins</Typography>
                        <div className="w-full flex flex-col gap-2">
                        {admins && admins.map((adminEmail) => (
                            <Typography variant="body1">{adminEmail}</Typography>
                        ))}
                            {!admins && <Typography variant="body1">No admins yet</Typography>}
                        </div>
                        <TextField
                            id="adminEmail"
                            name="adminEmail"
                            label="Provider admin email"
                            variant="standard"
                            margin="normal"
                            fullWidth
                            required
                            sx={visibleTextfield}
                            value={adminEmail}
                            onChange={(e) => setAdminEmail(e.target.value)}
                        />
                        <div className="flex flex-row-reverse w-full">
                            <Button variant="contained" onClick={addNewAdmin}>Add admin</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Footer displayLogo={true} selectedTab={"0"}/>
        </Box>
    );
};

export default Providers;