import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#27104E',
            contrastText: '#fff'
        },
        secondary: {
            main: '#4860E6',
            contrastText: '#fff'
        }
    },
    typography: {
        fontFamily: [
            'Poppins',
            'Roboto',
            'Helvetica Neue',
            'Arial',
            'sans-serif'
        ].join(','),
        h1: {
            color: "#27104E"
        },
        h2: {
            color: "#27104E"
        },
        h3: {
            color: "#27104E"
        },
        h4: {
            color: "#27104E"
        },
        h5: {
            color: "#27104E"
        },
        h6: {
            color: "#27104E",
            fontSize: "18px"
        },
        body1: {
            color: "#6C6363",
            fontSize: "16px"
        },
        body2: {
            color: "#6C6363",
            fontSize: "14px"
        },

    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        textTransform: "unset"
                    },
                },
                {
                    props: { variant: "outlined", color: "secondary" },
                    style: {
                        textTransform: "unset"
                    }
                },
                {
                    props: { variant: "contained" },
                    style: {
                        textTransform: "unset"
                    },
                },
                {
                    props: { variant: "contained", color: "secondary" },
                    style: {
                        textTransform: "unset"
                    }
                }
            ],
        },
    },

});